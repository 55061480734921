/*
*******************************
* menu ************************
*******************************
*/
const $menu = document.querySelector('.menu')
addEventListener('scroll', function () {
  if (window.scrollY > 10) {
    $menu.classList.add('menu-color')
  }
  if (window.scrollY === 0) {
    $menu.classList.remove('menu-color')
  }
})

/*
*******************************
* menu mobile *****************
*******************************
*/
const $menuMobile = document.querySelector('.menu-mobile')
const $btnMenuMobileOpen = document.querySelector('.btn-menu-mobile-open')
const $btnMenuMobileClose = document.querySelector('.btn-menu-mobile-close')
const $menuMobileListClose = document.querySelector('.menu-mobile-list-close')

$btnMenuMobileOpen.addEventListener('click', function () {
  $menuMobile.classList.add('menu-mobile-open')
})

$btnMenuMobileClose.addEventListener('click', function () {
  $menuMobile.classList.remove('menu-mobile-open')
})

$menuMobileListClose.addEventListener('click', function () {
  $menuMobile.classList.remove('menu-mobile-open')
})

/*
*******************************
* dashboard images ************
*******************************
*/
const $doctorPicture = document.getElementById('doctor-picture')
const $patientPicture = document.getElementById('patient-picture')
const $text = document.querySelector('.solutions-content-2 p')
const $medico = document.querySelector('#medico')
const $paciente = document.querySelector('#paciente')

$medico.addEventListener('click', function () {
  $doctorPicture.style.display = 'block'
  $patientPicture.style.display = 'none'
  $text.innerHTML = "médico(a)"
  $medico.style.backgroundColor = "var(--color-green-bcg)"
  $paciente.style.backgroundColor = "var(--color-white)"
})
$paciente.addEventListener('click', function () {
  $patientPicture.style.display = 'block'
  $doctorPicture.style.display = 'none'
  $text.innerHTML = "paciente"
  $paciente.style.backgroundColor = "var(--color-green-bcg)"
  $medico.style.backgroundColor = "var(--color-white)"
})

/*
*******************************
* send email ******************
*******************************
*/
const $values = { name: '', email: '', message: '' }

const $name = document.querySelector('input[name=name]')
const $email = document.querySelector('input[name=email]')
const $message = document.querySelector('textarea')

$name.addEventListener('change', function () {
  $values.name = $name.value
})
$email.addEventListener('change', function () {
  $values.email = $email.value
})
$message.addEventListener('change', function () {
  $values.message = $message.value
})

function alert(message, type) {
  $alert = document.querySelector(`#alert-${type}`)
  $alert.style.display = 'block'
  $alert.innerHTML = message
  setTimeout(() => {
    $alert.style.display = 'none'
  }, 5000)
  return
}

const $sendmail = document.querySelector('#sendmail')
$sendmail.addEventListener('submit', function (e) {
  e.preventDefault()

  if (!$values.name || !$values.email || !$values.message) {
    alert('Preencha todos os campos!', 'error')
    return
  }

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const encoded = JSON.stringify($values)

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: encoded,
    bodyUsed: true,
    mode: 'no-cors'
  }

  fetch("https://sendemail-pp8iwjk.uc.gateway.dev/send-email?key=AIzaSyD-OsKKE79UdtkU2WYBWjvE6KIb_b9XUsA", requestOptions)
    .then(() => alert('Mensagem enviada com sucesso!', 'success'))
    .catch(() => alert('Ocorreu um erro, tente novamente!', 'error'))
}, true)
